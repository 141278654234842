import Compressor from 'compressorjs';
import { $EventBus } from '@/main';

export default {
	name: 'InputFile',
	props: {
		value: {
			type: Array,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			defaultButtonText: 'Importar imagen',
			isSelecting: false,
		};
	},
	created() {},
	mounted() {},
	computed: {
		evidences: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		buttonText() {
			return this.label || this.defaultButtonText;
		},
	},
	watch: {},
	methods: {
		onButtonClick() {
			this.isSelecting = true;
			window.addEventListener(
				'focus',
				() => {
					this.isSelecting = false;
				},
				{ once: true }
			);

			this.$refs.uploader.click();
		},
		hasExtension(file) {
			let exts = ['.jpg', '.jpeg', '.png'];
			let fileName = file?.name;
			return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(
				fileName
			);
		},
		onFileChanged(e) {
			const files = [...e.target.files];
			let that = this;
			if (Array.isArray(files)) {
				files.forEach((file) => {
					if (this.hasExtension(file)) {
						new Compressor(file, {
							quality: 0.6,
							convertSize: 524288,
							success(result) {
								that.evidences.push({
									file: result,
									description: '',
									urlImage: URL.createObjectURL(result),
									urlPublic: null,
								});
							},
							error(err) {
								$EventBus.$emit(
									'showSnack',
									'warning',
									err?.message || 'Error al comprimir imagen.'
								);
							},
						});
					} else {
						$EventBus.$emit(
							'showSnack',
							'warning',
							'Seleccione el formato de archivo correcto'
						);
					}
				});
			}
			// do something
		},
	},
	components: {},
};
